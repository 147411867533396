import { h } from "preact";
import { DataProvider, useData } from "../../api";
import { CSSVars } from "../../components/CSSVars";
import { IconKlimaKachel } from "../../components/IconKlimaKachel";
import { IframeContainer } from "../../components/IframeContainer";
import { numberFormat, TenantContext } from "../../const";

import style from "./klimakachel-iframe.css";

interface Props {
  tenantId: string;
}

export default ({ tenantId }: Props) => {
  return (
    <TenantContext.Provider value={tenantId}>
      <DataProvider>
        <IframeContainer>
          <Content />
        </IframeContainer>
        <CSSVars />
      </DataProvider>
    </TenantContext.Provider>
  );
};

const urlParams = new URLSearchParams(window.location.search);

function Content() {
  let data = useData();

  let tiles = data.tiles;

  if (urlParams.has("tileId")) {
    tiles = tiles.filter((t) => t.id === urlParams.get("tileId"));
  }

  return (
    <div class={style.container}>
      {tiles.map((tile) => {
        return (
          <div
            key={tile.id}
            class={tile.url ? style.tileInteractive : style.tile}
            onClick={() => {
              if (tile.url) {
                window.open(tile.url, "_blank");
              }
            }}
          >
            <div class={style.tileLabel}>{tile.label}</div>
            <div class={style.tileContent}>
              <div class={style.tileIcon}>
                <IconKlimaKachel path={tile.icon} />
              </div>
              <div class={style.tileValue}>
                {numberFormat.format(tile.value)} tCO₂
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
