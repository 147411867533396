import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";

interface IconProps {
  path: string;
  width?: string;
  height?: string;
}

export const IconKlimaKachel = function ({
  path,
  width = "1em",
  height = "1em",
}: IconProps) {
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    fetch("/assets/icons/kachel/" + path)
      .then((response) => response.text())
      .then((text) => {
        text = text.replaceAll("#7EA337", "var(--primaryColor)");
        text = text.replaceAll("#7ea337", "var(--primaryColor)");
        text = text.replaceAll("#7EA338", "var(--primaryColor)");
        text = text.replaceAll("#7ea338", "var(--primaryColor)");
        text = text.replaceAll("#868789", "var(--secondaryColor)");
        text = text.replaceAll("#FFFFFF", "var(--backgroundColor)");
        text = text.replaceAll("#ffffff", "var(--backgroundColor)");

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "text/xml");

        const svg = xmlDoc.getElementsByTagName("svg")[0];

        svg.setAttribute("height", height);
        svg.setAttribute("width", width);

        setIcon(svg.outerHTML);
      });
  }, [path]);

  if (!icon) {
    return null;
  }

  return <span data-icon={path} dangerouslySetInnerHTML={{ __html: icon }} />;
};
