import { createContext, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import {
  PARSE_APP_ID,
  PARSE_HOST,
  TenantContext,
  UPDATE_INTERVAL_MS,
} from "./const";

interface HistoryInterface {
  /**
   * Summe der Einsparungen in diesem Monat in CO²
   */
  value: number;
  footprintActual: number | undefined;
  footprintTarget: number | undefined;
  footprintActualText: string;
  footprintTargetText: string;
  handprintText: string;
  scope1: number;
  scope2: number;
  scope3: number;
  handprintCategories: Record<
    string,
    {
      value: number;
      label: string;
      color: string;
    }
  >;
}

interface Result {
  /**
   * Unix-Timestamp in UTC (Date.now()) der den Start für die Berechnung gedient hat.
   */
  startingTimestamp: number;

  /**
   * Unix-Timestamp in UTC (Date.now()) der den Start für die Berechnung gedient hat.
   */
  startingYear: number;

  /**
   * Aktueller Unix-Timestamp in UTC (Date.now()) der für die Berechnung von currentValue benutzt wurde
   */
  currentTimestamp: number;

  /**
   * Einsparungen in CO²
   */
  currentValue: number;

  /**
   * Einsparungen die pro Sekunde hochgezählt werden in CO².
   */
  currentValuePerSecond: number;

  /**
   * Historische Werte pro Monat
   */
  history: Array<
    HistoryInterface & {
      /**
       * Jahr z.B.: 2022
       */
      year: number;
    }
  >;

  /**
   * Historische Werte pro Monat
   */
  historyMonthly: Array<{
    /**
     * Jahr z.B.: 2022
     */
    year: number;

    /**
     * Monat, 0 = Januar, 1 = Feb, ...
     */
    month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

    /**
     * Summe der Einsparungen in diesem Monat in CO²
     */
    value: number;
  }>;

  measureValues: Record<
    string,
    {
      value: number;
    }
  >;

  measureYearValues: Record<
    string,
    {
      value: number;
    }
  >;

  tiles: Array<{
    id: string;
    label: string;
    icon: string;
    url: string | null;
    value: number;
  }>;

  tenantConfig: {
    backgroundColor: string;
    backgroundDimmColor: string;
    backgroundImage?: any;
    baseYear: number;
    coverageAreaName: string;
    coverageAreaPopulation: number;
    coverageAreaSize: number;
    handprintColor: string;
    footprintActualColor: string;
    footprintTargetColor: string;
    memberOfKlimaschutzInitiative: boolean;
    numberOfCustomers: number;
    primaryColor: string;
    secondaryColor: string;
    comparativeFigures: string[];
  };
}

const defaultDataContext: Result = {
  startingYear: 2000,
  startingTimestamp: new Date(2000, 0, 1).valueOf(),
  currentTimestamp: Date.now(),
  currentValue: 0,
  currentValuePerSecond: 0,
  history: [],
  historyMonthly: [],
  measureValues: {},
  measureYearValues: {},
  tiles: [],
  tenantConfig: {
    backgroundColor: "#ffffff",
    backgroundDimmColor: "#ececec",
    backgroundImage: undefined,
    baseYear: 2020,
    coverageAreaName: "Beispiel Name",
    coverageAreaPopulation: 0,
    coverageAreaSize: 0,
    handprintColor: "81ad3a",
    footprintActualColor: "#acacac",
    footprintTargetColor: "#73c09f",
    memberOfKlimaschutzInitiative: false,
    numberOfCustomers: 0,
    primaryColor: "#81ad3a",
    secondaryColor: "#868789",
    comparativeFigures: [],
  },
};

export const DataContext = createContext<Result>(defaultDataContext);

export function useData(): Result {
  return useContext(DataContext);
}

export function useCurrentSaving(factor: number): number {
  const data = useData();

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const { currentTimestamp, currentValue, currentValuePerSecond } = data;

      setValue(currentValue);

      const interval = setInterval(() => {
        const now = Date.now();
        const diff = Math.floor(Math.max(now - currentTimestamp, 0) / 1000);

        const newValue = currentValue + diff * currentValuePerSecond;

        setValue(newValue);
      }, UPDATE_INTERVAL_MS);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [data]);

  return value * factor;
}

export function DataProvider({
  children,
}: {
  children: h.JSX.Element | h.JSX.Element[];
}) {
  const tenant = useContext(TenantContext);
  const [data, setData] = useState<Result>(defaultDataContext);

  useEffect(() => {
    callParseFunction("asew-klimazaehler-output", { tenant }).then(
      (result) => {
        setData(result);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  return <DataContext.Provider value={data} children={children} />;
}

async function callParseFunction(name: string, data: any) {
  const response = await fetch(PARSE_HOST + "/functions/" + name, {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": PARSE_APP_ID,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Bad response from backend");
  }

  const body = await response.json();

  return body.result;
}
