import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";

interface IconProps {
  path: string;
}

export const Icon = function ({ path }: IconProps) {
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    fetch(path)
      .then((response) => response.text())
      .then((text) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "text/xml");

        const svg = xmlDoc.getElementsByTagName("svg")[0];

        svg.setAttribute("fill", "currentColor");
        svg.setAttribute("stroke", "currentColor");
        svg.setAttribute("height", "1em");
        svg.setAttribute("width", "1em");

        setIcon(svg.outerHTML);
      });
  }, [path]);

  if (!icon) {
    return null;
  }

  return <span dangerouslySetInnerHTML={{ __html: icon }} />;
};
