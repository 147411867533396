import { h, Fragment, ComponentChildren } from "preact";
import { useEffect, useState } from "preact/hooks";

import style from "./Dialog.css";

interface DialogProps {
  visibile: boolean;
  title?: string;
  onClose: () => void;
  children: ComponentChildren;
}

export const Dialog = function ({
  visibile,
  title,
  onClose,
  children,
}: DialogProps) {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.keyCode === 27
      ) {
        onClose();
      }
    };

    window.document.addEventListener("keyup", handler);

    return () => {
      window.document.removeEventListener("keyup", handler);
    };
  }, []);

  if (!visibile) {
    return null;
  }

  return (
    <div class={style.dialog}>
      <div class={style.dialogHead}>
        <div
          class={style.dialogClose}
          onClick={() => {
            onClose();
          }}
        />
      </div>
      <div class={style.dialogContent}>{children}</div>
    </div>
  );
};
