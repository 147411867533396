import { h, Fragment } from "preact";
import { useRef, useState, useEffect } from "preact/hooks";

import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

interface ChartProps {
  config: any;
  width: string | number;
  height: string | number;
}

const style: h.JSX.CSSProperties = {
  width: "100%",
  height: "100%",
  border: "1px solid red",
};

export const ChartJs = function ({ config, height, width }: ChartProps) {
  const canvas = useRef<HTMLCanvasElement>(null);
  const chart = useRef<Chart>();

  // const div = useRef<HTMLDivElement>(null);

  // const [size, setSize] = useState<{ width: number; height: number }>({
  //   width: 0,
  //   height: 0,
  // });

  // console.log(size);

  // useEffect(() => {
  //   console.log("test");
  //   if (div.current) {
  //     console.log(div.current, {
  //       width: div.current.getBoundingClientRect().width,
  //       height: div.current.getBoundingClientRect().height,
  //     });
  //     setSize({
  //       width: div.current.getBoundingClientRect().width,
  //       height: div.current.getBoundingClientRect().height,
  //     });
  //   }
  // }, [div.current]);

  useEffect(() => {
    if (config && canvas.current) {
      chart.current = new Chart(canvas.current, config);
    }

    return () => {
      if (chart.current) {
        chart.current.destroy();
      }
    };
  }, [config]);

  return <canvas ref={canvas} height={height} width={width} />;
  // return (
  //   <div ref={div} style={style}>
  //     {size.width > 0 && size.height > 0 && (
  //       <canvas ref={canvas} width={size.width} height={size.height} />
  //     )}
  //   </div>
  // );
};
