import { h, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import { ANIMATION_STEPS, UPDATE_INTERVAL_MS } from "../const";
import style from "./AnimatedNumber.css";

interface AnimatedNumberProps {
  number: number;
  decimals: number;
  unit: string;
}

export const AnimatedNumber = function ({
  number,
  decimals,
  unit,
}: AnimatedNumberProps) {
  // TODO: Handle animation using spring
  // TODO: Start animation when element enters viewpoint

  const [displayNumber, setDisplayNumber] = useState(0);

  useEffect(() => {
    const diff = number - displayNumber;
    const step = diff / ANIMATION_STEPS;

    for (let i = 0; i < ANIMATION_STEPS; i++) {
      const timeout = (UPDATE_INTERVAL_MS / ANIMATION_STEPS) * i;

      setTimeout(() => {
        setDisplayNumber((current) => current + step);
      }, timeout);
    }
  }, [number]);

  const displayNumberString = displayNumber.toString();

  const decimalChars: string[] = [];
  const commaIndex = displayNumber.toString().indexOf(".");

  for (let i = 0; i < decimals; i++) {
    if (commaIndex >= 0) {
      decimalChars.push(displayNumberString.charAt(commaIndex + i + 1) || "0");
    } else {
      decimalChars.push("0");
    }
  }

  const chars = Math.floor(displayNumber).toString().split("");

  return (
    <div className={style.container}>
      {chars.map((char, i) => {
        const key = chars.length - i;

        return (
          <>
            {i !== 0 && key % 3 === 0 && (
              <span key={key + "dot"} className={style.komma}></span>
            )}
            <span key={key} className={style.number}>
              {char}
            </span>
          </>
        );
      })}

      <span className={style.komma}>,</span>

      {decimalChars.map((char, i) => (
        <span key={i} className={style.number}>
          {char}
        </span>
      ))}

      {/* <span className={style.unit}>{unit}</span> */}
    </div>
  );
};
