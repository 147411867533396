import { h } from "preact";
import { DataProvider } from "../../api";
import { CSSVars } from "../../components/CSSVars";
import { DefaultVisualisation } from "../../components/DefaultVisualisation";
import { IframeContainer } from "../../components/IframeContainer";
import { TenantContext } from "../../const";

interface Props {
  tenantId: string;
}

export default ({ tenantId }: Props) => {
  return (
    <TenantContext.Provider value={tenantId}>
      <DataProvider>
        <IframeContainer>
          <DefaultVisualisation />
        </IframeContainer>
        <CSSVars />
      </DataProvider>
    </TenantContext.Provider>
  );
};
