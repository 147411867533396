import { createContext } from "preact";
import { useEffect, useRef } from "preact/hooks";

export const TenantContext = createContext<string>("");

export const PARSE_HOST =
  "https://parse-asew-klimazaehler.apps.openinc.dev/parse";

export const PARSE_APP_ID = "asew";

export const UPDATE_INTERVAL_MS = 1000;
export const ANIMATION_STEPS = 10;

export const numberFormat = new Intl.NumberFormat("de-DE", {
  useGrouping: true,
});
