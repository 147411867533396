import { h } from "preact";
import { Route, Router } from "preact-router";

// Code-splitting is automated for `routes` directory
import Gesamtzähler from "../routes/klimazaehler/gesamtzaehler-iframe";
import KlimaKachel from "../routes/klimazaehler/klimakachel-iframe";
import KlimaKurve from "../routes/klimazaehler/klimakurve-iframe";

const App = () => (
  <div id="app">
    <Router>
      <Route
        path="/klimazaehler/iframe/:tenantId/gesamtzaehler"
        component={Gesamtzähler}
      />
      <Route
        path="/klimazaehler/iframe/:tenantId/klimakachel"
        component={KlimaKachel}
      />
      <Route
        path="/klimazaehler/iframe/:tenantId/massnahmenzaehler"
        component={KlimaKachel}
      />
      <Route
        path="/klimazaehler/iframe/:tenantId/klimakurve"
        component={KlimaKurve}
      />
    </Router>
  </div>
);

export default App;
