import { h, Fragment } from "preact";
import { useState } from "preact/hooks";

import style from "./Tooltip.css";

interface TooltipProps {
  text: string;
}

export const Tooltip = function ({ text }: TooltipProps) {
  const [active, setActive] = useState(false);

  return (
    <>
      <span
        class={style.icon}
        children="?"
        title={text}
        onClick={() => {
          setActive(true);
        }}
      />

      {active && (
        <div class={style.dialog}>
          <div class={style.dialogHead}>
            <div
              class={style.dialogClose}
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          <div class={style.dialogContent}>{text}</div>
        </div>
      )}
    </>
  );
};
