import { h } from "preact";
import { useData } from "../api";

export const CSSVars = function () {
  const data = useData();

  const c = data.tenantConfig;

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        :root {
          --primaryColor: ${c.primaryColor};
          --secondaryColor: ${c.secondaryColor};
          --backgroundColor: ${c.backgroundColor};
          --backgroundDimmColor: ${c.backgroundDimmColor};
        }
      `,
      }}
    />
  );
};
